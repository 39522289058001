import type { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { setPagesState } from 'root/utils/createPage';
import { DELETE_PAGE_ORIGIN } from 'root/utils/bi';
import { BiReporter } from 'root/utils/BiReporter';

type InitPageActionsEventListenerArgs = {
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
};

export const initPageActionsEventListener = async ({ editorSDK, flowAPI }: InitPageActionsEventListenerArgs) => {
  await editorSDK.addEventListener('pageDuplicated', async (event) => {
    await setPagesState({ editorSDK });
  });

  await editorSDK.addEventListener('componentDeleted', async (event) => {
    const { componentRef, componentType } = event.detail;
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    await biReporter.init({ editorSDK, reportError: flowAPI.reportError });
    if (componentType === 'mobile.core.components.Page') {
      biReporter.reportPageDelete(DELETE_PAGE_ORIGIN);
    }
  });
};
