import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { PageDefinition, ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { MENUS_SHOWCASE_APP_DEF_ID } from 'root/utils/consts';

export const DEFAULT_APP_WIDTH = 980;
export type ComponentStructure = NonNullable<PageDefinition['components']>[number];

export const WIDGET_IDS = {
  menus: '3f7c620e-526a-4dbb-afff-6fb77bfa8dcc',
};

export type PageData = {
  pageId: string;
  title: string;
  widgetId: string;
  pageUriSEO: string;
};

export const PAGE_DATA: PageData = {
  pageId: 'Menus',
  title: 'app.page.title',
  widgetId: WIDGET_IDS.menus,
  pageUriSEO: 'menus-ooi',
};

const addWidgetAsPage = async ({
  editorSDK,
  appDefId,
  pageData,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
}): Promise<PageRef> => {
  const { pageId, widgetId } = pageData;

  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    // @ts-expect-error
    const { pageRef } = await editorSDK.document.tpa.add.component('', {
      componentType: editorSDK.document.tpa.TPAComponentType.Page,
      appDefinitionId: appDefId,
      managingAppDefId: appDefId,
      widget: {
        widgetId,
        shouldNavigate: true,
      },
      page: {
        isHidden: false,
        pageId,
      },
    });
    return pageRef;
  });

  return pageRefPromise;
};

const setPagesState = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');

  const dupPages = applicationPages.filter((page) => page.tpaPageId === PAGE_DATA.pageId);

  editorSDK.document.pages.setState('', {
    state: {
      [PAGE_DATA.pageId]: dupPages.map((page) => ({ id: page.id })) as PageRef[],
    },
  });
};

const createAppPage = async ({
  editorSDK,
  appDefId,
  pageData,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
}): Promise<{ pageRef: PageRef; widgetRef?: ComponentRef }> => {
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    appDefId,
    pageData,
  });

  await setPagesState({ editorSDK });

  return { pageRef: ppPageRef };
};

export const createMenuOOIPage = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const { pageRef } = await createAppPage({
    editorSDK,
    appDefId: MENUS_SHOWCASE_APP_DEF_ID,
    pageData: PAGE_DATA,
  });

  try {
    await editorSDK.document.pages.navigateTo('', { pageRef });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Failed to navigate to menus page', e);
  }
};
