/* eslint-disable no-restricted-imports */
import type { MENU_WIDGET_PRESET_IDS, MENU_WIDGET_PRESET_IDS_MOBILE } from 'root/utils/consts';
import type { Menu as _Menu } from '@wix/ambassador-restaurants-menus-v1-menu/types';
import type { Section as _Section } from '@wix/ambassador-restaurants-menus-v1-section/types';
import type { PlaceholderImageDisplayValue, ZeroPriceDisplayValue } from './components/Menu/panels/Settings/types';
import type { ItemImage as Image, PopulatedItem, Label } from '@wix/restaurants-populated-item-client/types';
import type { ELEMENTS_PANEL_ACTION_CHECK, ELEMENTS_PANEL_ACTION_UNCHECK } from './utils/bi';

type NonNullableProps<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type Menu = NonNullableProps<_Menu>;
export type Section = NonNullableProps<_Section>;
export type ItemImage = NonNullableProps<Image>;
export type LabelWithSvg = Label & { svgId?: string };
export type MenusEntity = Menu | Section | Label | BaseShowcasePopulatedItem;

export type Preset =
  | (typeof MENU_WIDGET_PRESET_IDS)[keyof typeof MENU_WIDGET_PRESET_IDS]
  | (typeof MENU_WIDGET_PRESET_IDS_MOBILE)[keyof typeof MENU_WIDGET_PRESET_IDS_MOBILE];
export type PopulatedMenu = Menu & { sections: PopulatedSection[] };
export type PopulatedSection = Section & { items?: ShowcasePopulatedItem[] };

export type ShowcasePopulationLevel = {
  labels: true;
  modifierGroups: false;
  modifiers: false;
  priceVariants: true;
};
export type BaseShowcasePopulatedItem = PopulatedItem<ShowcasePopulationLevel>;
export type ShowcasePopulatedItem = Omit<BaseShowcasePopulatedItem, 'labels' | 'image'> & {
  labels?: LabelWithSvg[];
  image?: ItemImage;
};

export type WidgetDesignTabsArray = {
  label: string;
  roles: string[];
  dependents?: string[];
  tooltip?: string;
}[];

// todo: remove this when we have a proper type for the horizontal menu - https://wix.slack.com/archives/C8S1LLG2K/p1693491764493709
export type HorizontalMenuItem = {
  id?: string;
  label?: string;
  link?: string;
  selected: boolean;
};

export type ColumnsSectionSharedProps = {
  sections: PopulatedSection[];
  zeroPriceDisplayOption: ZeroPriceDisplayValue;
  zeroPriceDisplaySpecificSectionIds?: string[];
  placeholderImageDisplayValue: PlaceholderImageDisplayValue;
  sectionsWithPlaceholderImageIds?: string[];
};

export type WidgetsSharedProps = {
  preset: Preset;
  shouldDisplayCurrency: boolean;
  shouldDisplayVariantCurrency: boolean;
  placeholderImage?: string;
};

export type ElementData = {
  elementId?: string;
  label: string;
  identifier: { role: string };
  categoryId: string;
  index?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ControllerParams<T extends { createController: (...args: any) => any }> = Parameters<
  Parameters<T['createController']>[0]
>[0];

export type WidgetDesignVariables = Record<string, { value: number; unit: string }>;

export type ElementsPanelAction = typeof ELEMENTS_PANEL_ACTION_CHECK | typeof ELEMENTS_PANEL_ACTION_UNCHECK;

export enum DashboardPage {
  menus = 'menus',
  menu = 'menu',
  items = 'items',
}
