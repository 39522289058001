import type { EditorType } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK, TFunction, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { openMenuElementsPanel } from 'root/panels/ElementsPanel/elementsActions';
import { BiReporter } from 'root/utils/BiReporter';
import {
  MENU_ELEMENTS_ORIGIN,
  MENU_ELEMENTS_PANEL,
  LABEL_STYLE_ORIGIN,
  LABEL_STYLE_PANEL,
  MENU_SETTINGS_ORIGIN,
  MENU_SETTINGS_PANEL,
  CHOOSE_MENUS_PANEL,
  MENU_LAYOUT_CUSTOMIZATION_ORIGIN,
  MENU_LAYOUT_CUSTOMIZATION_PANEL,
  CHOOSE_MENUS_ORIGIN,
  MENU_DESIGN_ORIGIN,
  DESIGN_MENU_PANEL,
  DASHBOARD_LOAD_REFERRAL_INFO,
} from 'root/utils/bi';
import type { ELEMENT_DATA_BLACK_LIST_MAP } from 'root/panels/ElementsPanel/consts';
import { PANEL_IDS } from 'root/utils/consts';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { openManageMenuDashboardPanel } from 'root/utils/openManageMenuDashboardPanel';
import { openMenuSettingsPanel, openSettingsPanel } from './gfppEventListener.utils';
import { LAYOUT_CUSTOMIZATION_PANEL_NAME } from 'root/components/Menu/consts';
import type { ElementsPanelAction } from 'root/types';
import { DashboardPage } from 'root/types';
import { getBlocksPanelOpener } from 'root/utils/commonUtils';

type InitGfppEventListenerArgs = {
  editorSDK: FlowEditorSDK;
  editorType: EditorType;
  flowAPI: EditorScriptFlowAPI;
  fedopsLogger: FedopsLogger;
};

const TOKEN = 'token';

export const initGfppEventListener = async ({
  flowAPI,
  editorSDK,
  editorType,
  fedopsLogger,
}: InitGfppEventListenerArgs) => {
  const t = flowAPI.translations.t;
  const openBlocksPanel = await getBlocksPanelOpener(editorSDK, editorType, flowAPI.essentials);

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    await biReporter.init({ editorSDK, reportError: flowAPI.reportError });
    const experiments = flowAPI.experiments;
    const isReplaceLabelElementsExperimentEnabled = experiments.enabled(
      'specs.restaurants.replaceLabelElementsInPanel'
    );
    const isLabelsTooltipExperimentEnabled = experiments.enabled('specs.restaurants.labelsTooltipInElementsPanel');
    const isConvertNavigationBarToWidgetExperimentEnabled = experiments.enabled(
      'specs.restaurants.convertNavigationBarToWidget'
    );

    switch (id) {
      case PANEL_IDS.menuLayoutCustomization: {
        const title = t('layout-customization-panel.header-title');
        biReporter.reportMenuWidgetPanelBi(MENU_LAYOUT_CUSTOMIZATION_ORIGIN, MENU_LAYOUT_CUSTOMIZATION_PANEL);
        void openBlocksPanel(LAYOUT_CUSTOMIZATION_PANEL_NAME, componentRef, title);
        break;
      }
      case PANEL_IDS.manageMenus: {
        openManageMenuDashboardPanel(editorSDK, DashboardPage.menus, DASHBOARD_LOAD_REFERRAL_INFO.manageMenus);
        break;
      }
      case PANEL_IDS.menuElementsPanel: {
        fedopsLogger.openMenuElementsPanelStarted();
        const preset = await editorSDK.document.application.appStudioWidgets.getPreset(TOKEN, {
          componentRef,
        });

        biReporter.reportMenuWidgetPanelBi(MENU_ELEMENTS_ORIGIN, MENU_ELEMENTS_PANEL);

        void openMenuElementsPanel(
          editorSDK,
          componentRef,
          t as TFunction,
          (action: ElementsPanelAction, elementName: string) =>
            biReporter.reportElementCheckboxAction(action, elementName),
          fedopsLogger.openMenuElementsPanelEnded,
          preset.layout as keyof typeof ELEMENT_DATA_BLACK_LIST_MAP,
          isReplaceLabelElementsExperimentEnabled,
          isConvertNavigationBarToWidgetExperimentEnabled,
          isLabelsTooltipExperimentEnabled
        );
        break;
      }
      case PANEL_IDS.labelStyle: {
        biReporter.reportSectionWidgetPanelBi(LABEL_STYLE_ORIGIN, LABEL_STYLE_PANEL);

        void openBlocksPanel('Label Style', componentRef);
        break;
      }
      case PANEL_IDS.settings: {
        biReporter.reportMenuWidgetPanelBi(MENU_SETTINGS_ORIGIN, MENU_SETTINGS_PANEL);
        openSettingsPanel(editorSDK, componentRef, fedopsLogger, t as TFunction);

        break;
      }
      case PANEL_IDS.menuSettings: {
        biReporter.reportMenuWidgetPanelBi(CHOOSE_MENUS_ORIGIN, CHOOSE_MENUS_PANEL);
        openMenuSettingsPanel(editorSDK, componentRef, fedopsLogger, t as TFunction);
        break;
      }
      case PANEL_IDS.design: {
        biReporter.reportMenuWidgetPanelBi(MENU_DESIGN_ORIGIN, DESIGN_MENU_PANEL);
        break;
      }
    }
  });
};
